body {
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: black;
}

.Banner {
    width: 100vw;
    height: 60vh;

    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(122, 0, 0, 0.5)
    ), url('../images/Banner.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
}

.Banner-title{
    color: white;
    font-size: 3em;
    margin: 0;
}

.Banner p {
    font-size: 1.5em;
}

div.Home div.Banner button.Banner-contact-btn {
    border: none;
    width: 8%;
    height: 7%;
    border-radius: 25px;
    background-color: rgb(210, 12, 12);
    color: white;
    font-size: 1.2em;
    transition: transform 300ms;
}

div.Home div.Banner button.Banner-contact-btn a{
    color: white;
}

div.Home div.Banner button.Banner-contact-btn:hover{
    transform: translateY(-5px);
    cursor: pointer;
}