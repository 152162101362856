

@media only screen and (min-width: 320px) and (max-width: 899px){

    div.Services-container {
        flex-direction: column;
        height: 140vh;
    }

    div.Services-container div.Services-intro {
        width: 100vw;
        height: 25vh;
        margin-bottom: 50px;
    }

    div.Services-container div.Services-intro h1.introTitle{
        font-size: 1.1em;
    }

    div.Services-container div.Services-intro p.introDescription{
        font-size: 1em;
    }

    div.Services-container div.Services-list{
        width:100vw;
        height: 50vh;
        margin-left: 20px;
    }
}


@media only screen and (min-width: 1300px){
    div.Services-container{
        height: 60vh;
    }

    div.Services-container div.Services-list{
        width: 60vw;
    }

    div.Services-container div.Services-list div.Service-icon svg.Service-icon image{
        width: 60px;
    }

}

@media only screen and (min-width: 1600px){
    div.Services-container{
        height: 60vh;
    }

    div.Services-container div.Services-list{
        width: 60vw;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1300px){
    div.Services-container{
        flex-direction: column;
        align-items: center;
        height: 85vh;
    }

    div.Services-container div.Services-intro{
        width: 80vw;
    }

    div.Services-container div.Services-intro h1.introTitle{
        font-size: 1.5em;
    }

    div.Services-container div.Services-intro p.introDescription {
        font-size: 1.2em;
    }

    div.Services-container div.Services-list{
        width: 80vw;
    }

    div.Services-container div.Services-list div.Services-icon div.Service-description{
        padding: 50px;
    }

    div.Services-container div.Services-list div.Services-icon svg.Service-icon.icon image{
        width: 100vw;
    }
}