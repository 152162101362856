div.Map-container{
    display: flex;
    justify-content: space-between;
    align-items: center;

}


div.Map{
    margin-bottom: 70px;
    width: 100vw;
}

div.Map-info-details {
    text-align: left;
}

div.Map-info-details h4 a.Map-phone{
    color: rgb(210, 12, 12);
}

div.Map-info-details h4 a.Map-address{
    color: rgb(210, 12, 12);
}

.Map-info-details button {
    border: none;
    width: 40%;
    height: 20%;
    border-radius: 25px;
    background-color: rgb(210, 12, 12);
    color: white;
    font-size: 1em;
    transition: transform 300ms;
    padding: 10px;
}

.Map-info-details button:hover{
    transform: translateY(-5px);
    cursor: pointer;
}

iframe {
    position: relative;
    top: 3rem;

    width: 95vw;
    height: 30vh;
}

