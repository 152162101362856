@media only screen and (min-width: 320px) and (max-width: 899px){

    div.Navi div.MuiContainer-root.MuiContainer-maxWidthXl.css-19r6kue-MuiContainer-root{
        padding: 0;
        
    }

    div.Navi a {
        position: relative;
        top: 1rem;
        height: 10vh;
        align-items: center
        
    }

    div.Navi img.logo{
        width: 80vw;
    }
}