div.InfoBanner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15vh;
    flex-direction: column;

    background-color: rgb(32, 32, 32);
}

div.InfoBanner h2 {
    color: rgb(210, 12, 12);
    font-size: 1.8em;
}

div.InfoBanner div.InfoBanner-info{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}

div.InfoBanner div.InfoBanner-info button{
    background-color: rgb(210, 12, 12);
    border: none;
    width: auto;
    padding: 10px;
    height: auto;
    color: white;
    transition: transform 250ms;
    border-radius: 20px;
    font-size: 1.2em;
}

div.InfoBanner div.InfoBanner-info button:hover{
    cursor: pointer;
    transform: translateY(-10px);
}

