div.Footer {
    background-color: rgb(32, 32, 32);
    width: 100vw;
    height: 80px;

    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    
}