.Services-container{
    display: flex;
    
    width: 100vw;
    height: 50vh;
    margin: 0 auto;
    
    background-color: rgba(244, 244, 244, 0.922);
    
}

.Services-intro {
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    height: 100%;
    width: 50%;
    
}

.introTitle {
    color: rgb(210, 12, 12);
}


.Services-intro button a {
    color: white;
}

.Services-intro button:hover{
    transform: translateY(-5px);
    cursor: pointer;
}


.introDescription{
    font-size: 1.5em;
}

.Services-list {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   width: 80%;
   
}