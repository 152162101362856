.About {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px;

}

.banner {
    width: 50vw;
    
}

div.About-info h2{
    color: rgb(210, 12, 12);
}

div.About-info ul {
    list-style: none;
}

div.About-info ul li {
    text-align: left;
}

div.About-info ul li h3 {
    color: rgb(210, 12, 12);
}