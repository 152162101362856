/* @media only screen and (max-width: 320px){
    div.Map-container {
        flex-direction: column;
    }

    div.Map-container div.Map-info{
        width: 100vw;
    }
} */

@media only screen and (min-width: 900px) and (max-width: 1300px){
    
    div.Map-container {
        padding: 0;
    }

    div.Map-container div.Map iframe {
        width: 100vw;
    }
}

@media only screen and (min-width: 1300px) and (max-width: 1600px){
    div.Map-container {
        width: 80vw;
    }

    div.Map-container div.Map-info{
        text-align: left;
    }
}