.Service-description p {
    text-align: left;
    width: 80%;
}

.Services-title{
    color: rgb(210, 12, 12);
    text-align: left;
}

div.Service-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1;
    justify-content: space-between;
    transition: transform 250ms;
}

div.Service-icon:hover{
    transform: translateY(-10px);
    cursor: default;
}

div.Service-icon svg.Service-icon.icon {
    width: 15vw;
}

div.Service-icon svg.Service-icon.icon svg.Service-icon.icon image{
    width: 70%;
    height: 100%;
}