.InfoItem {
    color: white;
    width: 25vw;
}

div.InfoItem a.InfoItem-phone{
    color: grey;
}

div.InfoItem p {
    color: white;
}