@media only screen and (min-width: 320px) and (max-width: 899px){
    
    div.About {
        flex-direction: column;
    }

    div.About div.About-banner img{
        width: 90vw;
    }

    div.About div.About-info {
        width: 90vw;
    }

    div.About div.About-info h2{
        font-size: 1em;
    }
}

@media only screen and ( min-width: 900px ) and (max-width: 1300px) {
    div.About {
        flex-direction: column;
    }

    div.About div.About-banner img{
        width: 70vw;
    }
}


/* @media only screen and (max-width: 320px){

    div.About {
        flex-direction: column;
    }

    div.About div.About-banner img{
        width: 90vw;
        height: 30vh;
    }

    div.About div.About-info {
        width: 100vw;
    }

    div.About div.About-info ul{
        padding-left: 20px;
    }

    div.About div.About-info ul li{
        width: 80vw;
        font-size: .9em;
    }
} */

