/* Just to keep the media queries handy:

SMARTPHONE PORTRAIT
@media only screen and (min-width: 320px) and (max-width: 479px){ ... }
SMARTPHONE LANDSCAPE
@media only screen and (min-width: 480px) and (max-width: 767px){ ... }
TABLETS PORTRAI
@media only screen and (min-width: 768px) and (max-width: 991px){ ... }
TABLET LANDSCAPE / DESKTOP
@media only screen and (min-width: 992px){ ... }

These are supposed to be the most accessible for most screens */

@media only screen and (min-width: 320px) and (max-width: 900px){
    div.Home div.Banner{
        width: 100vw;
    }

    div.Home div.Banner h1.Banner-title{
        font-size: 1.5em;
    }

    div.Home div.Banner p{
        font-size: 1em;
    }

    div.Home div.Banner button.Banner-contact-btn{
        width: 50vw;
    }

    div.Home div.InfoBanner{
        flex-direction: column;
        height: 100vw;
    }

    div.Home div.InfoBanner div.InfoItem{
        width: 100vw;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1300px){

    div.Home div.Banner h1.Banner-title{
        font-size: 2em;
    }

    div.Home div.Banner p{
        font-size: 1.3em;
    }

    div.Home div.Banner button.Banner-contact-btn{
        width: 20vw;
    }

    div.Home div.InfoBanner{
        height: 10vh;
    }

    div.Home div.InfoBanner div.InfoItem{
        width: 50vw;
    }

    div.Home div.InfoBanner div.InfoItem h2{
        font-size: 1.5em;
    }

    div.Home div.InfoBanner div.InfoItem p{
        font-size: 1em;
    }

}

@media only screen and (min-width: 1300px){
    div.Home{
        overflow-x: hidden;
    }

    div.Home div.Banner button.Banner-contact-btn {
        width: 12vw;
    }
}

@media only screen and (min-width: 700px) and (max-width: 899px){
    div.Home div.Banner{
        width: 50vw;
    }
}