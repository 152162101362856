div.ServicesBanner {
    position: relative;
    bottom: 2rem;
    width: 100vw;
    height: 50vh;

    background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.5),
        rgba(122, 0, 0, 0.5)
    ), url('../images/ServicesBanner.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

div.ServicesBanner h1.ServicesBanner-title {
    color: white;
}

div.ServicePage-list {
    display: flex;
    justify-content: center;
    align-items: center;
    
   
}


div.ServicePage-list div.Service-icon.flex{
    height: 50%;
}
