header.MuiPaper-root {
    background-color: rgb(32, 32, 32);
    height: 10%;
}

div.MuiToolbar-root{
    height: 30%;
}

button.MuiButtonBase-root a{
    color: white;
    transition: .5s;
}

button.MuiButtonBase-root a:hover{
    color: rgb(171, 171, 171);
}

.Navi {
    border: 1px solid black;
}

.logo {
    position: relative;
    width: 300px;
    height: 300px;
}