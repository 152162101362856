
@media only screen and (min-width: 320px) and (max-width: 899px){
    div.Service-icon svg.Service-icon.icon{
        width: 70vw;
    }

    div.Service-icon svg.Service-icon.icon image {
        width: 60px;
    }

    div.Service-icon div.Service-description h3{
        font-size: 1em;
    }

    div.Service-icon div.Service-description p{
        font-size: .8em;
    }
}

@media only screen and (min-width: 900px) and (max-width: 1300px){
    
    div.Service-icon svg.Service-icon.icon {
       width: 20vw;
    }


    div.Service-icon svg.Service-icon.icon image {
        width: 50px;
        
    }

    div.Service-icon div.Service-description {
        margin-left: 20px;
    }
}